<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-notes">
      <path id="outline"
            d="M3 0.5H21C22.3807 0.5 23.5 1.61929 23.5 3V21C23.5 22.3807 22.3807 23.5 21 23.5H3C1.61929 23.5 0.5 22.3807 0.5 21V3C0.5 1.61929 1.61929 0.5 3 0.5Z"
            stroke="black"/>
      <path id="fold-line" d="M0.999657 4.99995H22.9997" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="line-top" d="M6.99966 12L17.9997 12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="line-bottom" d="M9 15L16 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="corner" d="M18.9997 23.5V19H22.9997" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconNotes = Vue.extend({
  name: 'icon-notes',
});
export default IconNotes;
</script>
