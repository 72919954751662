<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-hall">
      <rect id="Rectangle 293" x="0.5" y="0.5" width="23" height="23" rx="2.5" stroke="black"/>
      <g id="icon">
        <rect id="Rectangle" x="3.00027" y="6.00011" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
        <rect id="Rectangle_2" x="3" y="13.3076" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
        <rect id="Rectangle_3" x="9.76343" y="6" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
        <rect id="Rectangle_4" x="9.76343" y="13.3076" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
        <rect id="Rectangle_5" x="16.5273" y="6" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
        <rect id="Rectangle_6" x="16.5273" y="13.3076" width="4.47275" height="4.40045" rx="1" stroke="black"
              stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconHall = Vue.extend({
  name: 'icon-hall',
});
export default IconHall;
</script>
