<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-discussions">
      <g id="speech-bubble">
        <path id="bubble-outline"
              d="M0.5 11V1C0.5 0.723857 0.723858 0.5 1 0.5H23C23.2761 0.5 23.5 0.723858 23.5 1V11C23.5 11.2761 23.2761 11.5 23 11.5H19.4686C19.0422 11.5 18.6359 11.6815 18.3514 11.9991L17.0483 13.4537C16.8529 13.6718 16.5127 13.676 16.3119 13.4629L14.9067 11.9714C14.6233 11.6706 14.2282 11.5 13.8149 11.5H9.78305C9.36972 11.5 8.97471 11.6706 8.69128 11.9714L7.37786 13.3655C7.16121 13.5954 6.78825 13.5693 6.60577 13.3114L5.77252 12.1336C5.49135 11.7362 5.03482 11.5 4.54801 11.5H1C0.723858 11.5 0.5 11.2761 0.5 11Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="line-top"
              d="M20.1407 3.71566H2.9533C2.82753 3.73102 2.71186 3.79042 2.62801 3.88272C2.54415 3.97502 2.49788 4.09387 2.49788 4.21695C2.49788 4.34003 2.54415 4.45888 2.62801 4.55118C2.71186 4.64348 2.82753 4.70288 2.9533 4.71824H20.1407C20.2665 4.70288 20.3821 4.64348 20.466 4.55118C20.5498 4.45888 20.5961 4.34003 20.5961 4.21695C20.5961 4.09387 20.5498 3.97502 20.466 3.88272C20.3821 3.79042 20.2665 3.73102 20.1407 3.71566Z"
              fill="black"/>
        <path id="line-bottom"
              d="M20.1407 6.64886H2.9533C2.82753 6.66421 2.71186 6.72362 2.62801 6.81592C2.54415 6.90822 2.49788 7.02706 2.49788 7.15014C2.49788 7.27322 2.54415 7.39207 2.62801 7.48437C2.71186 7.57667 2.82753 7.63608 2.9533 7.65143H20.1407C20.2665 7.63608 20.3821 7.57667 20.466 7.48437C20.5498 7.39207 20.5961 7.27322 20.5961 7.15014C20.5961 7.02706 20.5498 6.90822 20.466 6.81592C20.3821 6.72362 20.2665 6.66421 20.1407 6.64886V6.64886Z"
              fill="black"/>
      </g>
      <g id="person-left">
        <path id="body"
              d="M3.50128 20.4897C4.96152 20.4897 6.26214 21.7502 6.47281 23.5H0.529745C0.740408 21.7502 2.04103 20.4897 3.50128 20.4897Z"
              stroke="black"/>
        <path id="head"
              d="M5.50182 18.4858C5.50182 19.595 4.60494 20.4923 3.50091 20.4923C2.39688 20.4923 1.5 19.595 1.5 18.4858C1.5 17.3767 2.39688 16.4794 3.50091 16.4794C4.60494 16.4794 5.50182 17.3767 5.50182 18.4858Z"
              stroke="black"/>
      </g>
      <g id="person-center">
        <path id="body_2"
              d="M12.0215 20.4897C13.4817 20.4897 14.7823 21.7502 14.993 23.5H9.04993C9.2606 21.7502 10.5612 20.4897 12.0215 20.4897Z"
              stroke="black"/>
        <path id="head_2"
              d="M14.022 18.4858C14.022 19.595 13.1251 20.4923 12.0211 20.4923C10.9171 20.4923 10.0202 19.595 10.0202 18.4858C10.0202 17.3767 10.9171 16.4794 12.0211 16.4794C13.1251 16.4794 14.022 17.3767 14.022 18.4858Z"
              stroke="black"/>
      </g>
      <g id="person-right">
        <path id="body_3"
              d="M20.4987 20.4897C21.959 20.4897 23.2596 21.7502 23.4703 23.5H17.5272C17.7379 21.7502 19.0385 20.4897 20.4987 20.4897Z"
              stroke="black"/>
        <path id="head_3"
              d="M22.4993 18.4858C22.4993 19.595 21.6024 20.4923 20.4984 20.4923C19.3943 20.4923 18.4975 19.595 18.4975 18.4858C18.4975 17.3767 19.3943 16.4794 20.4984 16.4794C21.6024 16.4794 22.4993 17.3767 22.4993 18.4858Z"
              stroke="black"/>
      </g>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconDiscussions = Vue.extend({
  name: 'icon-discussions',
});
export default IconDiscussions;
</script>
