<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-result">
      <rect id="bar-4" x="18.5" y="0.5" width="4" height="21" rx="1.5" stroke="black"/>
      <rect id="bar-3" x="12.5" y="9.5" width="4" height="12" rx="1.5" stroke="black"/>
      <rect id="bar-1" x="0.5" y="12.5" width="4" height="9" rx="1.5" stroke="black"/>
      <rect id="bar-2" x="6.5" y="4.5" width="4" height="17" rx="1.5" stroke="black"/>
      <rect id="base" y="23" width="24" height="1" rx="0.5" fill="black"/>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconResult = Vue.extend({
  name: 'icon-result',
});
export default IconResult;
</script>
