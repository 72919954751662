<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-program">
      <rect x="0.5" y="0.504288" width="23" height="5" rx="1.5" stroke="black"/>
      <rect x="0.5" y="18.5043" width="23" height="5" rx="1.5" stroke="black"/>
      <rect x="15.5" y="9.50429" width="8" height="5" rx="1.5" stroke="black"/>
      <rect y="10.0043" width="13" height="1" rx="0.5" fill="black"/>
      <rect y="13.0043" width="13" height="1" rx="0.5" fill="black"/>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconProgram = Vue.extend({
  name: 'icon-program',
});
export default IconProgram;
</script>
