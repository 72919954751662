<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-info">
      <rect id="Rectangle 292" x="0.5" y="0.504288" width="23" height="23" rx="2.5" stroke="black"/>
      <path id="Vector"
            d="M12.5851 9.88166C14.2117 9.88166 15.531 8.56138 15.531 6.94297C15.531 5.32457 14.2075 4.00429 12.5851 4.00429C10.9584 4.00429 9.63916 5.32457 9.63916 6.94297C9.63916 7.72662 9.94656 8.46342 10.5016 9.02135C11.0566 9.57927 11.7995 9.88166 12.5851 9.88166ZM12.5851 4.82201C13.7592 4.82201 14.7113 5.77176 14.7113 6.94297C14.7113 8.11419 13.7592 9.06394 12.5851 9.06394C11.411 9.06394 10.4589 8.11419 10.4589 6.94297C10.4589 5.77602 11.411 4.82201 12.5851 4.82201Z"
            fill="black"/>
      <path id="Vector_2"
            d="M15.9881 16.2957H15.6124C15.5057 16.2957 15.4203 16.2105 15.4203 16.104V11.4532C15.4203 10.8953 14.9677 10.4439 14.4084 10.4439H9.01186C8.45256 10.4439 8 10.8953 8 11.4532V12.2795C8 12.8374 8.45256 13.2889 9.01186 13.2889H9.55835C9.66509 13.2889 9.75047 13.374 9.75047 13.4805V16.104C9.75047 16.2105 9.66509 16.2957 9.55835 16.2957H9.18264C8.62334 16.2957 8.17078 16.7471 8.17078 17.3051V18.1313C8.17078 18.6892 8.62334 19.1407 9.18264 19.1407H15.9881C16.5474 19.1407 17 18.6892 17 18.1313V17.3051C17 16.7471 16.5474 16.2957 15.9881 16.2957ZM8.99051 17.3051C8.99051 17.1986 9.0759 17.1134 9.18264 17.1134H9.55835C10.1176 17.1134 10.5702 16.662 10.5702 16.104V13.4805C10.5702 12.9226 10.1176 12.4711 9.55835 12.4711H9.01186C8.90512 12.4711 8.81973 12.386 8.81973 12.2795V11.4532C8.81973 11.3468 8.90512 11.2616 9.01186 11.2616H14.4084C14.5152 11.2616 14.6006 11.3468 14.6006 11.4532V16.104C14.6006 16.662 15.0531 17.1134 15.6124 17.1134H15.9881C16.0949 17.1134 16.1803 17.1986 16.1803 17.3051V18.1313C16.1803 18.2378 16.0949 18.3229 15.9881 18.3229H9.18264C9.0759 18.3229 8.99051 18.2378 8.99051 18.1313V17.3051Z"
            fill="black"/>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconInfo = Vue.extend({
  name: 'icon-info',
});
export default IconInfo;
</script>
