


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

import { mapGetters, mapState } from 'vuex';
// import iconTickets from '../../svg/icons/Icon-tickets';
import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';

// @ts-ignore
import badgeNotificationsMixin from '@/mixins/badge-notifications.mixin.js';
import { TContact } from '@/_types/contact.type';
import { TBadgeNotifications } from '@/_modules/notifications-popup/types/badgeNotifications.type';
import { TUser } from '@/_types/user.type';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

type TSideBarLeftMenuItem = {
  routeName: string;
  active: boolean;
  isActive?: () => boolean;
  isDisabled?: () => boolean;
  title?: TranslateResult;
  iconComponentName?: string;
}

@Component({
  name: 'promo-side-bar-left',
  components: {
    // iconTickets,
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
  },
  computed: {
    ...mapState('userStore', {
      userInfo: 'user_info'
    }),
    ...mapState('contactStore', {
      contactInfo: 'contact'
    }),
    ...mapState('eventStore', {
      page_status: 'page_status'
    }),
    ...mapGetters('_badgeNotificationsStore', {
      badgeNotifications: 'getNotifications'
    })
  },
  mixins: [badgeNotificationsMixin],
})
export default class PromoSideBarLeft extends Vue {

  // DATA
  public badgeNotificationsPollingInterval: number = 0;
  public page_status: string;
  public menuItems: TSideBarLeftMenuItem[] = [
    {
      routeName: 'event-info',
      active: true,
      isActive: (): boolean => this.$route.name === 'event-info',
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.info'),
      iconComponentName: 'icon-home',
    },
    {
      routeName: 'promo-live',
      active: true,
      isActive: (): boolean => this.$route.name === 'promo-live',
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.live'),
      iconComponentName: 'icon-info',
    },
    {
      routeName: 'promo-program',
      active: true,
      isActive: (): boolean => this.$route.name === 'promo-program',
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.program'),
      iconComponentName: 'icon-program',
    },
    {
      routeName: 'event-tickets',
      active: false,
      title: this.$t('sideBar.tickets'),
      iconComponentName: 'icon-tickets',
    },
    {
      routeName: 'promo-page-events-companies',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-events') === 0 || this.$route.name === 'promo-profile') && (this.$route.name !== 'promo-page-events-contacts') && (this.$route.name !== 'promo-page-events-contacts-messages'),
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.hall'),
      iconComponentName: 'icon-hall',
    },
    {
      routeName: 'promo-page-events-contacts',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-contacts') === 0) || (this.$route.name === 'promo-page-events-contacts') || (this.$route.name.indexOf('promo-contact-') > -1),
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.contacts'),
      iconComponentName: 'icon-contacts',
    },
    {
      routeName: 'promo-page-calendar',
      active: true,
      isActive: (): boolean => this.$route.name.indexOf('promo-page-calendar') > -1,
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.calendar'),
      iconComponentName: 'icon-meetings',
    },
    {
      routeName: 'promo-page-events-contacts-messages',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-contacts-messages') === 0) || (this.$route.name === 'promo-page-events-contacts-messages'),
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.messages'),
      iconComponentName: 'icon-discussions',
    },
    {
      routeName: 'notes-list',
      active: true,
      isActive: (): boolean => this.$route.name === 'notes-list',
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.notes'),
      iconComponentName: 'icon-notes',
    },
    {
      routeName: 'result',
      active: true,
      isActive: (): boolean => this.$route.name === 'result-personal' || this.$route.name === 'result-company',
      isDisabled: (): boolean => this.page_status === 'loading',
      title: this.$t('sideBar.result'),
      iconComponentName: 'icon-result',
    },
  ];

  // mapped
  public readonly userInfo: TUser;
  public readonly contactInfo: TContact;
  public badgeNotifications: TBadgeNotifications;

  // COMPUTED
  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get activeMenuItems(): TSideBarLeftMenuItem[] {
    return this.menuItems.filter((x) => x.active);
  }

  // PROPS

  @Prop({ type: Boolean, default: false })
  public readonly accessCheckNecessary: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isAuthenticated: boolean;

  @Prop({ type: String, default: '' })
  public readonly gotoProp: string; // TODO: looks like it is not being used, check and remove

  // WATCH

  // HOOKS

  public created(): void {
    if (this.isAuthenticated) {
      this.$store.dispatch('userStore/getUserInfo');
      this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);
    }
  }

  public mounted(): void {
    // We need this check because if we don't have it,
    // badge notification triggers Auth, which can redirect to login page.
    // event-info can be viewed without Auth
    if (this.isAuthenticated) {
      this.pollBadgeNotificationCount();
      this.startBadgeNotificationPolling();
    }
  }

  public beforeDestroy(): void {
    this.endBadgeNotificationPolling();
  }

  // METHODS

  private menuItemClickHandler(event: Event, value: string): void {

    event.preventDefault();

    if (!value) {
      return;
    }

    this.$emit('promoGoto', value); // TODO: do we need this?

    // For showPromoAccessPopup
    // If this prop is true, we don't continue to the switch,
    // only doing the $emit
    if (this.accessCheckNecessary) {
      return;
    }

    try {
      this.$router.push({
        name: value,
        params: { eventId: this.$route.params.eventId }
      });
    } catch {
      /* ignore */
    }
  }

  private startBadgeNotificationPolling(): void {
    if (this.badgeNotificationsPollingInterval) {
      return;
    }
    this.badgeNotificationsPollingInterval = window.setInterval(this.pollBadgeNotificationCount, 10 * 1000);
  }

  private endBadgeNotificationPolling(): void {
    if (this.badgeNotificationsPollingInterval) {
      window.clearInterval(this.badgeNotificationsPollingInterval);
    }
  }

  private pollBadgeNotificationCount(): void {
    // this.$store.dispatch('badgeNotificationsStore/getBadgeNotificationsCount', { eventId: this.$route.params.eventId });
    this.$store.dispatch('_badgeNotificationsStore/getBadgeNotificationsCount', Number(this.$route.params.eventId));
  }

}
