<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-home">
      <path
            d="M23.4178 10.5832L23.4077 10.5732L13.4244 0.590281C13.0439 0.209382 12.538 7.62939e-06 11.9999 7.62939e-06C11.4619 7.62939e-06 10.9559 0.209382 10.5755 0.590281L0.585423 10.5804C-0.196019 11.3666 -0.19506 12.6434 0.587819 13.4263C0.944762 13.7833 1.41909 13.9917 1.92408 14.0137L1.93366 14.0142C1.95235 14.0161 1.96816 14.0166 1.98349 14.0166H2.62072V21.6058C2.62072 22.9282 3.69682 24.0043 5.01919 24.0043H8.9264C9.18991 24.0043 9.40408 23.7901 9.40408 23.5266V17.7643C9.40408 16.9684 10.0514 16.3212 10.8472 16.3212H13.1517C13.9476 16.3212 14.5948 16.9684 14.5948 17.7643V23.5261C14.5948 23.7896 14.809 24.0038 15.0725 24.0038H18.9797C20.3021 24.0038 21.3782 22.9277 21.3782 21.6053V14.0161H21.9867C22.5247 14.0161 23.0307 13.8063 23.4111 13.4258C24.194 12.6439 24.1964 11.3685 23.4178 10.5832ZM22.7365 12.7508C22.5362 12.951 22.2703 13.0612 21.9876 13.0612H20.9015C20.638 13.0612 20.4238 13.2754 20.4238 13.5389V21.6058C20.4238 22.4016 19.7765 23.0489 18.9807 23.0489H15.5512V17.7643C15.5512 16.4419 14.4751 15.3658 13.1527 15.3658H10.8481C9.52529 15.3658 8.44967 16.4419 8.44967 17.7643V23.0484H5.02014C4.22433 23.0484 3.57704 22.4012 3.57704 21.6053V13.5389C3.57704 13.2754 3.36287 13.0612 3.09936 13.0612L2.01607 13.0608C2.00888 13.0603 2.0017 13.0598 1.99451 13.0598C1.71758 13.055 1.45838 12.9453 1.26433 12.7508C0.856604 12.343 0.852292 11.6752 1.25523 11.2622L1.29212 11.2253L11.2516 1.26584C11.4513 1.06604 11.7173 0.955848 11.9999 0.955848C12.2826 0.955848 12.5485 1.06604 12.7483 1.26584L22.7389 11.2554C23.149 11.6684 23.1476 12.3392 22.7365 12.7508Z"
            fill="black"/>
    </g>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconHome = Vue.extend({
  name: 'icon-home',
});
export default IconHome;
</script>
